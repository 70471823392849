import HomePage from '@components/pages/Home';
import NotFoundPage from '@components/pages/NotFound';

export default class Router {
  constructor() {
    this.container = document.querySelector('.c-Main');
    this.currentPage = null;
    this.routes = {
      '/': HomePage,
      '/work': () => import('@components/pages/Work').then(m => m.default),
      '/about': () => import('@components/pages/About').then(m => m.default),
      '/contact': () => import('@components/pages/Contact').then(m => m.default),
      '/xoxo': () => import('@components/pages/Xoxo').then(m => m.default)
    };

    this.init();
  }

  init() {
    // Handle initial route
    this.handleRoute(window.location.pathname);

    // Handle browser navigation
    window.addEventListener('popstate', () => {
      this.handleRoute(window.location.pathname);
    });

    // Handle link clicks
    document.addEventListener('click', (e) => {
      const link = e.target.closest('a');
      if (link && link.href.startsWith(window.location.origin)) {
        e.preventDefault();
        const url = new URL(link.href);
        this.navigate(url.pathname);
      }
    });
  }

  async handleRoute(pathname) {
    const PageComponent = this.routes[pathname];
    
    if (!PageComponent) {
      // Show 404 page
      this.currentPage = new NotFoundPage(this.container);
      return;
    }

    // Show loading state
    this.container.innerHTML = '<div class="c-Loader"></div>';

    try {
      // Load page component (handles both sync and async imports)
      const Component = typeof PageComponent === 'function' ? 
        await PageComponent() : PageComponent;

      // Clear old page instance
      if (this.currentPage) {
        // Cleanup if needed
      }

      // Create new page instance
      this.currentPage = new Component(this.container);

      // Update active nav link
      this.updateActiveNav(pathname);

      // Scroll to top
      window.scrollTo(0, 0);

    } catch (error) {
      console.error('Error loading page:', error);
      this.currentPage = new NotFoundPage(this.container);
    }
  }

  navigate(pathname) {
    // Update URL
    window.history.pushState({}, '', pathname);
    
    // Handle the new route
    this.handleRoute(pathname);
  }

  updateActiveNav(pathname) {
    document.querySelectorAll('.c-Nav-link').forEach(link => {
      const isActive = link.getAttribute('href') === pathname;
      link.classList.toggle('is-active', isActive);
    });
  }
} 