import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Swiper from 'swiper';
import Navigation from './modules/navigation';
import Router from './modules/router';

// Register GSAP plugins
gsap.registerPlugin(ScrollTrigger);

class App {
  constructor() {
    this.init();
  }

  init() {
    // Initialize navigation
    this.navigation = new Navigation();

    // Initialize router
    this.router = new Router();

    // Initialize scroll animations
    this.setupScrollAnimations();

    // Initialize year in footer
    this.updateYear();
  }

  setupScrollAnimations() {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('is-visible');
          observer.unobserve(entry.target);
        }
      });
    }, observerOptions);

    // Observe all animatable elements
    const elements = document.querySelectorAll([
      '.c-Hero',
      '.c-Section',
      '.c-Grid',
      '.c-Card',
      '.c-Service'
    ].join(','));

    elements.forEach(element => observer.observe(element));
  }

  updateYear() {
    const yearElement = document.getElementById('year');
    if (yearElement) {
      yearElement.textContent = new Date().getFullYear();
    }
  }
}

// Initialize app when DOM is ready
document.addEventListener('DOMContentLoaded', () => {
  new App();
}); 