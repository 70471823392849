export default class HomePage {
  constructor(container) {
    this.container = container;
    this.render();
  }

  render() {
    this.container.innerHTML = `
      <section class="c-Hero">
        <div class="c-Hero-content">
          <h1 class="c-Hero-title">We create digital experiences</h1>
          <p class="c-Hero-text">TWO is an independent UX & design studio based in Prague, Czech Republic. We create high quality digital solutions.</p>
          <a href="/work" class="c-Button c-Button--primary">View Our Work</a>
        </div>
      </section>

      <section class="c-Section">
        <div class="c-Section-inner">
          <h2 class="c-Section-title">Featured Projects</h2>
          <div class="c-Grid">
            <article class="c-Card c-Card--hover">
              <div class="c-Card-image">
                <img src="/src/images/projects/project1.jpg" alt="Project 1">
              </div>
              <div class="c-Card-body">
                <h3 class="c-Card-title">Project Name</h3>
                <p class="c-Card-text">Brief project description goes here.</p>
              </div>
            </article>
            <!-- More project cards -->
          </div>
        </div>
      </section>

      <section class="c-Section c-Section--dark">
        <div class="c-Section-inner">
          <h2 class="c-Section-title">Our Services</h2>
          <div class="c-Grid c-Grid--3">
            <div class="c-Service">
              <h3 class="c-Service-title">UX Design</h3>
              <p class="c-Service-text">Creating intuitive and engaging user experiences.</p>
            </div>
            <div class="c-Service">
              <h3 class="c-Service-title">Development</h3>
              <p class="c-Service-text">Building robust and scalable digital solutions.</p>
            </div>
            <div class="c-Service">
              <h3 class="c-Service-title">Branding</h3>
              <p class="c-Service-text">Crafting unique and memorable brand identities.</p>
            </div>
          </div>
        </div>
      </section>
    `;
  }
} 