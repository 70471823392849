export default class NotFoundPage {
  constructor() {
    this.template = `
      <div class="c-Page">
        <div class="c-Page-section">
          <div class="c-Page-header">
            <h1 class="c-Page-header-title">404 - Page Not Found</h1>
            <p class="c-Page-header-subtitle">The page you're looking for doesn't exist or has been moved.</p>
          </div>
          <div class="c-Page-content">
            <a href="/" class="c-Button c-Button--primary">Go Home</a>
          </div>
        </div>
      </div>
    `;
  }

  mount() {
    return this.template;
  }

  unmount() {
    // Cleanup if needed
  }
} 