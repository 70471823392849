export default class Navigation {
  constructor() {
    this.nav = document.querySelector('.c-Nav');
    this.toggle = document.querySelector('.c-Nav-toggle');
    this.links = document.querySelectorAll('.c-Nav-link');
    this.isOpen = false;
    this.isTransitioning = false;

    this.init();
  }

  init() {
    this.bindEvents();
    this.setActiveLink();
  }

  bindEvents() {
    // Toggle menu on button click
    this.toggle?.addEventListener('click', () => this.toggleMenu());

    // Handle navigation links
    this.links?.forEach(link => {
      link.addEventListener('click', (e) => this.handleNavigation(e));
    });

    // Close menu on escape key
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape' && this.isOpen) {
        this.closeMenu();
      }
    });
  }

  toggleMenu() {
    if (this.isTransitioning) return;
    this.isOpen ? this.closeMenu() : this.openMenu();
  }

  openMenu() {
    this.isTransitioning = true;
    this.isOpen = true;
    this.nav.classList.add('is-open');
    document.body.style.overflow = 'hidden';

    setTimeout(() => {
      this.isTransitioning = false;
    }, 600); // Match transition duration from CSS
  }

  closeMenu() {
    this.isTransitioning = true;
    this.isOpen = false;
    this.nav.classList.remove('is-open');
    document.body.style.overflow = '';

    setTimeout(() => {
      this.isTransitioning = false;
    }, 600); // Match transition duration from CSS
  }

  setActiveLink() {
    const currentPath = window.location.pathname;
    this.links?.forEach(link => {
      const linkPath = link.getAttribute('href');
      if (currentPath === linkPath || (currentPath === '/' && linkPath === '/index.html')) {
        link.parentElement.classList.add('is-active');
      } else {
        link.parentElement.classList.remove('is-active');
      }
    });
  }

  async handleNavigation(e) {
    if (this.isTransitioning) {
      e.preventDefault();
      return;
    }

    const link = e.currentTarget;
    const href = link.getAttribute('href');
    
    // Don't handle external links or anchors
    if (href.startsWith('http') || href.startsWith('#')) return;

    e.preventDefault();
    
    // Start transition
    this.closeMenu();
    document.querySelector('.c-Page-transition')?.classList.add('is-active');

    // Wait for transition
    await new Promise(resolve => setTimeout(resolve, 600));

    // Navigate to new page
    window.location.href = href;
  }
} 